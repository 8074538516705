<template>
    <v-container>
        <div class="font-weight-bold mb-5 primary--text">{{$route.params.link === 'profile-tags' ? 'Profile Tags' : ($route.params.link === 'single-tags' ? 'Single Tags' : 'Multiple Tags')}}</div>
        <v-row>
            <v-col v-for="tag in tags" :key="tag.id" cols="12" sm="6" lg="3" xl="2">
                <v-card>
                    <v-card-title class="primary--text">{{tag.name}}</v-card-title>
                    <v-card-text v-if="$route.params.link === 'profile-tags'">
                        <div v-if="tag.profile_tags.length">
                            <div class="text-capitalize" v-for="item in tag.profile_tags" :key="item.id">{{item.profile_column + ' : ' + item.value}}</div>
                        </div>
                        <p v-else class="text-center">No Profile properetirs  linked yet</p>
                    </v-card-text>
                    <v-card-text v-else>
                        <div v-if="tag.answers.length">
                            <div class="text-capitalize my-3" v-for="item in tag.answers" :key="item.id">
                                <strong>- {{item.question.interest_question}}</strong>
                                <br>
                                {{'-' + item.Answer}}
                            </div>
                        </div>
                        <p v-else class="text-center">No answers linked yet</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import admin from '@/web_services/admin'

export default {
  data () {
    return {
      tags: []
    }
  },
  methods: {
    getTags () {
      admin.getLinkedTags(this.$route.params.link).then(response => {
        this.tags = response.data.data
      })
    }
  },
  created () {
    this.getTags()
  }
}
</script>
